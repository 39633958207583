import {$, hide, show} from "../../core/js/Dom.ts";
import {$post} from "../../core/js/Ajax.ts";
import {
    createErrorNotification,
    createSuccessNotification,
    createWarningNotification
} from "./Notifications.ts";

document.addEventListener('DOMContentLoaded', () => {

    const subBtn = $<HTMLButtonElement>('#webpush-subscribe-button')!;
    const serviceWorker = $<HTMLMetaElement>('meta[name="service-worker-js"]');

    if (subBtn && serviceWorker) {
        const serviceWorkerPath = serviceWorker.content;
        subBtn.addEventListener('click', handleClick);

        async function handleClick() {
            subBtn.disabled = true;
            if (window.isPushEnabled) {
                const registration = await navigator.serviceWorker.getRegistration(serviceWorkerPath)
                if(registration) return unsubscribe(registration)
                else {
                    createErrorNotification({message: 'Impossible de vous désabonner, une erreur est survenu.'})
                }
            }

            // Do everything if the Browser Supports Service Worker
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register(serviceWorkerPath).then((reg: ServiceWorkerRegistration) => {
                    subBtn.innerText = 'Chargement en cours....';
                    initialiseState(reg);
                });
            }
            // If service worker not supported, show warning to the message box
            else {
                createWarningNotification({message: 'Les notifications ne sont pas supportées par votre navigateur !'})
            }
        }

        function initialiseState(reg: ServiceWorkerRegistration) {
            // Are Notifications supported in the service worker?
            if (!(reg.showNotification)) {
                // Show a message and activate the button
                createWarningNotification({message: 'Les notifications ne sont pas supportées par votre navigateur !'})
                subBtn.innerText = 'Abonnez-vous aux notifications Push';
                return;
            }

            // Check the current Notification permission.
            // If its denied, it's a permanent block until the
            // user changes the permission
            if (Notification.permission === 'denied') {
                // Show a message and activate the button
                createWarningNotification({message: 'Votre navigateur bloque les notifications Push !'})
                subBtn.innerText = 'Abonnez-vous aux notifications Push';
                subBtn.disabled = false;
                return;
            }

            // Check if push messaging is supported
            if (!('PushManager' in window)) {
                // Show a message and activate the button
                createWarningNotification({message: 'Les notifications ne sont pas supportées par votre navigateur !'})
                subBtn.innerText = 'Abonnez-vous aux notifications Push';
                subBtn.disabled = false;
                return;
            }

            // We need to subscribe for push notification and send the information to server
            subscribe(reg)
        }

        function subscribe(reg: ServiceWorkerRegistration) {
            // Get the Subscription or register one
            getSubscription(reg).then((subscription: PushSubscription) => {
                postSubscribeObj(reg, 'subscribe', subscription);
            }).catch((error: any) => {
                if(window.Openscop.debug) console.error('Subscription error.', error)
            })
        }

        function urlB64ToUint8Array(base64String: string) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/-/g, '+')
                .replace(/_/g, '/');

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        }

        async function getSubscription(reg: ServiceWorkerRegistration) {
            const subscription = await reg.pushManager.getSubscription();
            let metaObj: HTMLMetaElement;
            let applicationServerKey: string;
            let options: any;
            // Check if Subscription is available
            if (subscription) {
                return subscription;
            }
            metaObj = $<HTMLMetaElement>('meta[name="django-webpush-vapid-key"]')!;
            applicationServerKey = metaObj.content;
            options = {
                userVisibleOnly: true,
            };
            if (applicationServerKey) {
                options.applicationServerKey = urlB64ToUint8Array(applicationServerKey);
            }
            return reg.pushManager.subscribe(options);
        }

        function unsubscribe(reg: ServiceWorkerRegistration) {
            // Get the Subscription to unregister
            reg.pushManager.getSubscription().then((subscription: PushSubscription | null) => {
                        // Check we have a subscription to unsubscribe
                        if (!subscription) {
                            // No subscription object, so set the state
                            // to allow the user to subscribe to push
                            subBtn.disabled = false;
                            createErrorNotification({message: 'Abonnement impossible !'})
                            return;
                        }
                        postSubscribeObj(reg, 'unsubscribe', subscription);
                    })
        }

        function postSubscribeObj(registration: ServiceWorkerRegistration, statusType: string, subscription: PushSubscription) {
            // Send the information to the server with fetch API.
            // the type of the request, the name of the user subscribing,
            // and the push subscription endpoint + key the server needs
            // to send push messages
            // @ts-ignore
            const browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase()
            const user_agent = navigator.userAgent
            const data = {
                status_type: statusType,
                subscription: subscription.toJSON(),
                browser: browser,
                user_agent: user_agent,
                group: subBtn.dataset.group
            }

            const headers = new Headers()
            headers.append('Content-Type', 'application/json')
            $post(subBtn.dataset.url!, data, headers, {
                credentials: 'include'
            }).then(({response}) => {
                if ((response.status == 201) && (statusType == 'subscribe')) {
                    // Show unsubscribe button instead
                    subBtn.innerText = 'Se désabonner des notifications Push';
                    subBtn.disabled = false;
                    window.isPushEnabled = true;
                    createSuccessNotification({message: 'Vous avez activé les notifications Push.'})
                    hide($<HTMLElement>("#alert_notif_push"))
                }

                // Check if the information is deleted from server
                if ((response.status == 202) && (statusType == 'unsubscribe')) {
                    // Get the Subscription
                    getSubscription(registration)
                        .then((subscription: PushSubscription) => {
                            // Remove the subscription
                            subscription.unsubscribe().then(() => {
                                subBtn.innerText = 'Abonnez-vous aux notifications Push';
                                createSuccessNotification({message: 'Vous vous êtes bien désabonner au notifications Push.'})
                                window.isPushEnabled = false;
                                subBtn.disabled = false;
                                show($<HTMLElement>("#alert_notif_push"))
                            })
                        }).catch(() => {
                                createErrorNotification({message: 'Erreur dans le traitement de la demande.'})
                        subBtn.innerText = 'Se désabonner des notifications Push';
                        subBtn.disabled = false;
                    });
                }
            })
        }
    }
})
