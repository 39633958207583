import {$get} from "../../core/js/Ajax.ts";
import {
    $,
    html_chargement_en_cours,
    createElement,
    $$,
    createSpinner,
    getParentElementByTagName,
    getParentElementByClassName,
    empty
} from "../../core/js/Dom.ts";
import {initSelects} from "../../core/js/Select.ts";
import {createModale, generateModale, registerModalListener, showModal} from "../../core/js/Modal.ts";
import {initTooltips} from "../../core/js/Tooltip.ts";
import {
    initAdministrationService,
    initAgentAdminActions,
    removeUserOrganisation,
    handleServiceLinkClicked,
    initInvitationsBlock
} from "../../structure/js/AdministrationDesServices.ts";
import {SelecteurDeServices} from "../../core/js/SelecteurDeServices.ts";
import {animateLoadingButton, fadeOutFadeIn} from "../../core/js/Animate.ts";
import {refreshPage} from "../../core/js/Utils.ts";
import {InitChampAdress} from "../../core/js/InitChampAdress.ts";

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.dataset.url?.startsWith('profile')) {
        ajaxLoadUserProfile()
        initSuiviToggle()
        initUserList()
        ajaxLoadOrganizationProfile()
    }
    $('#btn-fermeture-modale')?.addEventListener("click", function () {
        refreshPage()
    })
})

const ajaxLoadUserProfile = () => {
    const content = $('#detail_profil')
    const loader = html_chargement_en_cours()
    content?.prepend(loader)
    $get(window.Openscop.url_detail_user, {
        object_id: window.Openscop.user_id
    }).then(({body}) => {
        const conteneur = createElement('div')
        conteneur.innerHTML = body
        loader.remove()
        content?.prepend(conteneur)
        initSelects(conteneur)
        initTooltips(conteneur)
        initUserDetailListener(conteneur)
    }).catch(() => {
        if (content) {
            empty(content)
            content.innerHTML = `<div class='fr-alert fr-alert--warning fr-py-4w'>Vous n'êtes pas habilité à voir ces informations.</div>`
        }
    })
}


const ajaxLoadOrganizationProfile = () => {
    // Sélectionnez tous les éléments .organisation-panel
    const organizationPanels = $$<HTMLElement>('.organisation-panel');

    // Pour chaque élément .organisation-panel
    organizationPanels.forEach((panel) => {
        // Sélectionnez le contenu enfant .organisation-panel-content
        const content = $('.organisation-panel-content', panel);
        if (!content) return; // Vérifiez si le contenu existe

        // Créez un loader et ajoutez-le au contenu
        const loader = html_chargement_en_cours();
        content.prepend(loader);

        // Effectuez une requête Ajax
        $get(panel.dataset.url!, {ajax: true}).then(({ body }) => {
            const fragment = document.createRange().createContextualFragment(body);
            // Supprimez le loader
            loader.remove();

            // Ajoutez le conteneur au contenu de l'organisation-panel
            content.prepend(fragment);

            // Initialisez les selects, tooltips, etc. dans le conteneur
            initSelects(content);
            initTooltips(content);
            initUserDetailListener(content);
            $$('a.service-list-item', content).forEach(el => el.addEventListener('click', handleServiceLinkClicked))
            initInvitationsBlock(content)
            registerModalListener(content)
        }).catch(error => {
            if(window.Openscop.debug) console.error('Une erreur s\'est produite :', error);
        });
    });
};

const initSuiviToggle = () => {
    const switchs = ($$<HTMLInputElement>('.notification_switch'))
    switchs.forEach(input => {
        input.addEventListener('input', (event) => {
            event.preventDefault()
            const spinner = createSpinner()
            input.parentNode?.parentNode?.append(spinner)
            let type_message = input.getAttribute('data-type_message')
            let affichage = input.checked ? "1" : "0"
            let orga_id = input.getAttribute('data-orga_id')
            $get(window.Openscop.url_changer_notification_organisation, {
                type_message,
                affichage,
                orga_id
            }).finally(() => {
                spinner.remove()
            })
        })
    })
}

const initUserList = () => {
    const container = $('#user-services')
    if(container) {
        $('.btn-modal-rejoindre-organisation')?.addEventListener('click', showModalRejoindreOrganisation)
        initAgentAdminActions(container)
        initAdministrationService()
        $$('.quitter-structure', container).forEach(el => el.addEventListener('click', toggleQuitteOrganisation))
        $$('.dismis-quitter', container).forEach(el => el.addEventListener('click', toggleQuitteOrganisation))
        $$('.valide-delete-organisation').forEach(el => el.addEventListener('click', handleValidRemoveUserFromOrganisation))
    }
}

const initUserDetailListener = (container: Document | HTMLElement = document) => {
    // const container = $(id_conteneur)
    if(container) {
        $$('.edit-interaction', container).forEach(el => el.addEventListener('click', handleClickShowSimpleForm))
    }
}

export const show_form = (id: string, title: string, url: string, data_service: boolean | string) => {
    const spinner = html_chargement_en_cours()
    const modalId = `${id}-modal`
    generateModale(modalId, {
        title: title,
        content: spinner
    })
    $get(url, {
        'service_id': data_service || undefined,
    }).then(response => {
        spinner.remove()
        const content = $(`#${modalId}-modal__content`)
        if (content) {
            content.innerHTML = response.body
            initSelects(content)
            // // Supprime l'adresse e-mail d'un utilisateur inactif
            $("#delete_user_email")?.addEventListener('click', function (event: DOMEvent<HTMLElement>) {
                const user_id = event.target?.dataset.user;
                const organisation_id = event.target?.dataset.service;
                if (confirm('Confirmez vous vouloir supprimer l\'adresse e-amail de cet utilisateur ? \n' +
                    'Une nouvelle devra lui être attribué avant de pouvoir être activé à nouveau')) {
                    $get("/structure/delete_user_email/",{'object_id': user_id, 'organisation_id': organisation_id,
                    }).then(
                        function () {
                            window.location.reload()
                        }
                    )
                }
            })
        }
        if(id == "edit-service-adresse-orga"){
            InitChampAdress('id_adresse_s')
        }
    })
}

export const handleClickShowSimpleForm = (event: DOMEvent<HTMLElement>) => {
    const target = getParentElementByTagName(event.target, 'button')
    if(target) {
        const id = target.id
        const url = target?.dataset.url || ''
        const title = target?.dataset.title || ''
        const service = target?.dataset.service || false
        show_form(id, title, url, service)
    }
}


function showModalRejoindreOrganisation() {
    const modale = createModale('rejoindre_orga', {
        title: window.Openscop.selecteur.rejoindre_orga.modal_title,
    })
    if (modale) {
        showModal('rejoindre_orga', {
            disableScroll: true,
        })
        new SelecteurDeServices({
            ...window.Openscop.selecteur.rejoindre_orga,
        })
    }
}

function toggleQuitteOrganisation(event: DOMEvent<HTMLElement>) {
    const target = getParentElementByTagName(event.target, 'button')
    if(target) {
        const parent = getParentElementByClassName(target, 'quitter_btn')
        if(parent) {
            let cible
            if(target.classList.contains('quitter-structure')) {
                cible = $('.confirm-delete-actions', parent)
            } else {
                cible = $('.quitter-structure', parent)
            }
            fadeOutFadeIn(target, cible)
        }
    }
}

function handleValidRemoveUserFromOrganisation(event: DOMEvent<HTMLElement>) {
    const target = getParentElementByTagName(event.target, 'button')
    animateLoadingButton(target)
    const dataSwitch = target?.dataset.switch
    if (target) {
        if ($$(`[aria-label="organisation-tab"] > li`).length < 2 && dataSwitch === "user") {
            if (confirm('Attention, ceci est la dernière organisation de l\'utilisateur. En la retirant, son compte sera désactivé. ' +
                'L\'utilisateur ne pourra plus utiliser la plateforme')) {
                removeUserOrganisation(target.id, target?.dataset.user, target?.dataset.url)?.then(() => {
                    refreshPage()
                })
            }
        } else {
            removeUserOrganisation(target.id, target?.dataset.user, target?.dataset.url)?.then(() => {
                refreshPage()
            })
        }
    }
}